import axios from "axios";
import * as React from "react";
import isAfter from "date-fns/isAfter";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Card from "./card";
import AccountLayout from "./account-layout";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { Link } from "../../lib/components/Link";
import { useConfig } from "@fitplan/context/lib-es/config";
import { navigateTo } from "../../lib/UrlUtils";
import Layout from "../../components/layout/layout";
import { graphql } from "gatsby";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const Redeem = ({ data: { ribbon, header, footer } }) => {
  const { loggedIn, user, access_token } = useAuthContext();
  const { apiBaseUrl } = useConfig();

  const isFitplanMember = React.useMemo(
    () =>
      user &&
      user.paymentStoreType !== 0 &&
      user.paymentExpirationTimestamp &&
      isAfter(user.paymentExpirationTimestamp, new Date()),
    [user]
  );

  const { t, language } = useTranslation();
  const [error, setError] = React.useState("");
  const [redemptionCode, setRedemptionCode] = React.useState("");
  const [description, setDescription] = React.useState("");
  const onChange = (event) => {
    setRedemptionCode(event.target.value);
  };
  if (!loggedIn) {
    return navigateTo("/login");
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    setDescription("");
    setError("");
    try {
      const result = await axios({
        method: "POST",
        url: `${apiBaseUrl}/v1/redeem/apply/${redemptionCode}`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (result.data.result) {
        setRedemptionCode("");
        setDescription(`${result.data.result.message}`);
        setError("");
      } else {
        setError(result.data.error);
      }
    } catch (err) {
      const response = err.response;
      if (response) {
        const data = err.response.data;
        if (data && data.error) {
          setError(data.error);
        } else if (data && data.result) {
          setError(data.result.message || "Invalid Code");
        } else {
          setError("Unknown Error occurred. Please try again later.");
          throw err;
        }
      }
    }
  };
  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="white-banner"
    >
      <div className="account-container">
        <AccountLayout currentPage="/account/redeem">
          <Card>
            <div className="bg-white">
              <div className="max-w-screen-xl mx-auto ">
                <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-100 sm:text-4xl sm:leading-10">
                  {isFitplanMember
                    ? "Have a redemption code?"
                    : "To active a redemption code, you must have an active Fitplan subscription"}
                  <br className="hidden sm:inline" />
                  {isFitplanMember ? (
                    <span className="text-brand-400"> {t("redeemHere")}</span>
                  ) : (
                    <Link to="/subscribe" className="text-brand-400">
                      {" "}
                      {t("subscribeHere")}
                    </Link>
                  )}
                </h2>
                {isFitplanMember && (
                  <div>
                    <form
                      className="mt-8 sm:flex"
                      aria-labelledby="newsletter-headline"
                      onSubmit={onSubmit}
                    >
                      <input
                        aria-label="Email address"
                        type="text"
                        className="appearance-none w-full px-5 py-3 border border-gray-300 text-base leading-6 rounded-md text-gray-100 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:max-w-xs"
                        placeholder={t("enterYourRedemptionCode")}
                        value={redemptionCode}
                        onChange={onChange}
                      />
                      <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                        <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-xl leading-6 font-bold rounded-md text-white bg-brand-400 hover:bg-brand-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                          {t("redeemText")}
                        </button>
                      </div>
                    </form>
                    <div className="text-red-600 mt-2">{error}&nbsp;</div>
                    {description && (
                      <h2 className="mt-16 text-3xl leading-9 font-extrabold tracking-tight text-gray-100 sm:text-4xl sm:leading-10">
                        {t("success")} {description}
                      </h2>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Card>
        </AccountLayout>
      </div>
    </Layout>
  );
};

export default Redeem;
